import React from "react";
import BlogArticles from "./BlogArticles";

const AllBlogs = () => {
  return (
    <div>
      <BlogArticles />
    </div>
  );
};

export default AllBlogs;
